/**
 * See
 * https://github.com/LeKoArts/gatsby-starter-prismic/blob/master/src/components/SEO/SEO.jsx
 * for a more complete seo component
 */

import { graphql, useStaticQuery } from 'gatsby'
import React, { FC } from 'react'
import Helmet from 'react-helmet'

export interface SEOProps {
  title?: string | null
  desc?: string | null
  pathname?: string | null
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteLanguage
      }
    }
  }
`

const SEO: FC<SEOProps> = ({ title, desc, pathname }) => {
  const { site } = useStaticQuery(query)

  const {
    siteMetadata: {
      siteUrl,
      defaultTitle,
      titleTemplate,
      defaultDescription,
      siteLanguage,
    },
  } = site

  const seo = {
    title: title || undefined,
    description: desc || defaultDescription,
    url: `${siteUrl}${pathname || ''}`,
  }

  return (
    <>
      <Helmet
        defaultTitle={defaultTitle}
        title={seo.title}
        titleTemplate={titleTemplate}
      >
        <html lang={siteLanguage} />
        <meta name="description" content={seo.description} />
      </Helmet>
    </>
  )
}
export default SEO

SEO.defaultProps = {
  title: null,
  desc: null,
  pathname: null,
}
