import { graphql, Link, useStaticQuery } from 'gatsby'
import GatsbyImage, { FixedObject } from 'gatsby-image'
import React, { FC } from 'react'

export interface NavigationItem {
  id: string
  label: string
  path?: string
}

export interface NavPageItem {
  label: string
  path: string
}

interface NavigationProps {
  pages: NavPageItem[]
  items: NavigationItem[]
}

const logoQuery = graphql`
  query LogoQuery {
    file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "logo.png" }
    ) {
      childImageSharp {
        fixed(width: 62) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const Navigation: FC<NavigationProps> = ({ pages, items }) => {
  const logo: FixedObject = useStaticQuery(logoQuery).file.childImageSharp.fixed

  const styles = {
    link: `mt-2 mx-2 md:mx-3 lg:mx-4 text-gray-700 font-light tracking-widest hover:text-gray-900`,
    linkActive: ``,
  }
  styles.linkActive = `${styles.link} ${styles.linkActive}`

  return (
    <nav>
      <div className="bg-green h-4"></div>
      <div className="container mx-auto px-4 py-2 flex flex-row items-center">
        <Link to="/" aria-label="Link to home">
          <GatsbyImage fixed={logo} />
        </Link>
        <div className="ml-4">
          {pages.map(({ label, path }, i) => (
            <Link
              key={i}
              to={path}
              className={`override-focus ${styles.link} border-2 border-gray-300 px-6 py-2 rounded-full hover:bg-gray-100 focus:outline-none focus:ring`}
            >
              {label}
            </Link>
          ))}
        </div>
        <div className="flex-1"></div>
        <div className="hidden md:block flex flex-row flex-wrap justify-end">
          {items.map((item) =>
            item.path ? (
              <Link
                to={item.path || `#${item.id}`}
                key={item.id}
                className={styles.link}
              >
                {item.label}
              </Link>
            ) : (
              <a href={`#${item.id}`} key={item.id} className={styles.link}>
                {item.label}
              </a>
            )
          )}
        </div>
      </div>
    </nav>
  )
}

export default Navigation
