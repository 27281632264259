import React, { FC } from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/Footer'
import Navigation, {
  NavigationItem,
  NavPageItem,
} from '../components/Navigation'
import './index.css'

interface LayoutProps {
  navPages?: NavPageItem[]
  navItems?: NavigationItem[]
}

const Layout: FC<LayoutProps> = ({ navPages, navItems, children }) => (
  <>
    <Helmet
      htmlAttributes={{ class: 'scroll-behavior-smooth' }}
      bodyAttributes={{ class: 'font-body text-gray-900' }}
    />
    <Navigation pages={navPages || []} items={navItems || []} />
    {children}
    <Footer />
  </>
)

export default Layout
