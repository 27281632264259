import React, { DetailedHTMLProps, FC, HTMLAttributes } from 'react'

interface HProps {
  center?: boolean
  onDark?: boolean
  small?: boolean
  smallest?: boolean
  children: string
}

const H: FC<
  HProps &
    DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>
> = ({
  children,
  onDark = false,
  center = false,
  small = false,
  smallest = false,
  className,
  ...props
}) => {
  if (typeof children !== 'string') {
    throw new Error('children must be of type string')
  }

  const words = children.split(' ')
  const underlineColor = onDark ? 'bg-green' : 'bg-orange'

  const size = smallest
    ? `text-lg`
    : small
    ? `text-4xl`
    : `text-4xl md:text-5xl xl:text-6xl`

  const underline = smallest
    ? `h-3 -ml-2`
    : `h-4 lg:h-6 mb-1 md:mb-2 ml-1 -mr-1`

  return (
    <h1
      className={
        `font-display ${size} tracking-widest uppercase mb-2` +
        (center ? ` text-center mx-auto` : ``) +
        (className ? ` ${className}` : ``)
      }
      {...props}
    >
      {words.map((word, i) => (
        <span key={i} className="relative inline-block pr-3 md:pr-4">
          <span
            className={`absolute bottom-0 left-0 right-0 ${underline} ${underlineColor}`}
          ></span>
          <span className="relative">{word}</span>
        </span>
      ))}
    </h1>
  )
}

export default H
