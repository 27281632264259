import { graphql, Link, useStaticQuery } from 'gatsby'
import GatsbyImage, { FixedObject } from 'gatsby-image'
import React, { FC } from 'react'

const footerQuery = graphql`
  query FooterQuery {
    logo: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "logo.png" }
    ) {
      childImageSharp {
        fixed(width: 70) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    pages: allMarkdownRemark(limit: 100) {
      edges {
        node {
          frontmatter {
            path
            title
          }
        }
      }
    }
  }
`

const Navigation: FC = () => {
  const data = useStaticQuery(footerQuery)
  const logo: FixedObject = data.logo.childImageSharp.fixed
  const pages: any[] = data.pages.edges

  return (
    <footer className="relative">
      <div className="container mx-auto px-4 pt-12 flex flex-col items-center">
        <a
          href="#"
          title="Scroll to top"
          onClick={(e) => {
            e.preventDefault()
            window.scrollTo(0, 0)
          }}
        >
          <GatsbyImage fixed={logo} className="mt-8" />
        </a>
        <div className="my-4">
          {pages.map(({ node: { frontmatter: { path, title } } }, i) => (
            <Link key={i} className="mx-2" to={path}>
              {title}
            </Link>
          ))}
        </div>
        <div className="mb-4 mx-auto max-w-2xl">
          <p className="text-xs text-gray-500 text-center">
            Google Play und das Google Play-Logo sind Marken von Google LLC.
            Apple, the Apple logo, iPhone, and iPad are trademarks of Apple
            Inc., registered in the U.S. and other countries and regions. App
            Store is a service mark of Apple Inc.
          </p>
        </div>
      </div>
      <div className="bg-green h-4"></div>
    </footer>
  )
}

export default Navigation
